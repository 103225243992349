import { useContext, useMemo } from 'react';

import EnrollButton from '@/app/components/core/EnrollButton';
import { MarkdownRichText } from '@/app/components/shared';

import { createSignInURL } from '@/utils/onboarding-urls';
import ClientContext from '@/contexts/client.context';
import { Wrapper, Label, FormWrapper } from './styles';
import { Heading, Paragraph, RichText } from '@swordhealth/ui-corporate';
import { Logos } from '@/app/components/shared/Logos/Logos';
import { products } from '@/utils/products';
import FormComponent from '@/components/platform/Form/Form';
import { ACTIONS, BUTTON_TYPES, trackButtonClicked } from '@/utils/track';

const onboardingBaseURL = process.env.NEXT_PUBLIC_ONBOARDING_BASE_URL
  ? process.env.NEXT_PUBLIC_ONBOARDING_BASE_URL
  : 'https://onboarding.swordhealth.com/';

const BUTTON_LABEL = 'Sign in here';

const HeaderContent = ({ content, logo, showSwordLogo = true }) => {
  const { title, subtitle, eligibilityClaim, button } = content;
  const { client } = useContext(ClientContext);

  const signInURL = createSignInURL(
    onboardingBaseURL,
    client.institutionKey ?? client.clientKey,
    client?.country?.data?.attributes?.ISOCode.toUpperCase() ?? 'US',
  );

  const logos = useMemo(() => {
    if (!logo) {
      return [];
    }
    if (Array.isArray(logo)) {
      return logo.slice(0, 3);
    }
    return [logo];
  }, [logo]);

  return (
    <Wrapper>
      <Logos logos={logos} showSwordLogo={showSwordLogo} headerContent={true} />
      <Heading id="hero-title" variant="2" as="h1">
        {title}
      </Heading>
      {subtitle && <Paragraph id="hero-subtitle">{subtitle}</Paragraph>}
      {client?.redirectToApp && client.clientKey ? (
        <EnrollButton
          id="hero-button"
          isActive={client.isActive}
          client={client}
          buttonLabel={button?.label}
          comingSoonHref={client?.comingSoonHref}
          redirectToApp={client?.redirectToApp}
          buttonApp={{ label: button?.label, href: button?.href }}
          triage={client?.hasTriage}
          product={products[client?.product?.key?.toUpperCase()]}
          buttonLocation="header"
        />
      ) : client.clientKey && (!client?.countries || client?.countries?.length < 2) ? (
        <EnrollButton
          id="hero-button"
          isActive={client.isActive}
          client={client}
          buttonLabel={button?.label}
          comingSoonHref={client?.comingSoonHref}
          redirectToApp={client?.redirectToApp}
          buttonApp={{ label: button?.label, href: button?.href }}
          triage={client?.hasTriage}
          product={products[client?.product?.key?.toUpperCase()]}
          buttonLocation="header"
        />
      ) : (
        <FormWrapper>
          <FormComponent
            client={client}
            product={client.product}
            enrollButtonId="hero-form-button"
          />
        </FormWrapper>
      )}
      {client.isActive &&
        client.clientKey &&
        !client.redirectToApp &&
        client?.countries?.length < 2 &&
        products[client?.product?.key?.toUpperCase()]?.key !== 'mind' && (
          <RichText as={Paragraph}>
            Already have an account?{' '}
            <a
              href={signInURL}
              onClick={() => {
                trackButtonClicked({
                  action: ACTIONS.REDIRECT_TO_SIGN_IN,
                  buttonLocation: 'header',
                  buttonText: BUTTON_LABEL,
                  buttonType: BUTTON_TYPES.NAVIGATION,
                  destination: signInURL,
                  page_title: '',
                });
              }}
            >
              {BUTTON_LABEL}
            </a>
          </RichText>
        )}
      {eligibilityClaim && (
        <Label id="hero-eligibility-claim">
          <MarkdownRichText elements={['p', 'strong', 'em', 'a', 'u']}>
            {eligibilityClaim}
          </MarkdownRichText>
        </Label>
      )}
    </Wrapper>
  );
};

export default HeaderContent;
